import { createRoot } from 'react-dom/client';
import React, { lazy, Suspense } from 'react';
import 'regenerator-runtime/runtime';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/browser';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

import './style.css';
import FullPageLoader from './components/FullPageLoader';

const cssRule =
  'color: rgb(249, 162, 34);' +
  'font-size: 30px;' +
  'font-weight: bold;' +
  'text-shadow: 1px 1px 5px rgb(249, 162, 34);' +
  'filter: dropshadow(color=rgb(249, 162, 34), offx=1, offy=1);';

const cssRuleDescription = 'color: rgb(0, 0, 0);' + 'font-size: 18px;';

setTimeout(console.info.bind(console, '%cAVISO', cssRule), 0);
setTimeout(
  console.info.bind(
    console,
    '%cO uso deste console talvez permita que invasores falsifiquem sua identidade e roubem informações por meio de um ataque chamado Self-XSS.Não digite nem cole códigos que você não conhece.',
    cssRuleDescription
  ),
  0
);

Sentry.init({
  dsn: 'https://bd51b464fe5d6a9f72ede9797cb09f2a@o4506190517764096.ingest.sentry.io/4506190519205888',
  integrations: [
    new BrowserTracing(),
    new CaptureConsoleIntegration({ levels: ['error'] }) as any,
    new Sentry.Replay(),
  ],
  // @ts-ignore
  environment: process.env.NODE_ENV || import.meta.env.NODE_ENV,
  enabled:
    window.location.origin === 'https://meet.rheunion.com' ||
    window.location.origin === 'https://meet-dev.rheunion.com',
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const userAgent = navigator.userAgent;
Sentry.configureScope((scope) => {
  scope.setExtra('User Agent', userAgent);
});

const LazyApp = lazy(() => import('./app'));

const Wrapper = () => {
  return (
    <Suspense fallback={<FullPageLoader />}>
      <LazyApp />
    </Suspense>
  );
};

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<Wrapper />);
